import { ref, watch } from 'vue';

import { PageState } from '../providers/page/pageState';
import { useAppStore } from '../stores/app';

const firstEnter = ref(true);

type PageStateCallback = (state?: boolean) => void;

export const usePageReady = (cb: PageStateCallback = () => {}) => {
    watch(
        () => useAppStore().pageReady,
        (val) => val && cb(val)
    );
};

export const usePageEnter = (cb: PageStateCallback = () => {}) => {
    const appStore = useAppStore();
    watch(
        () => appStore.pageState,
        (val) => {
            if (val === PageState.EnterStart) {
                cb(appStore.firstPageRoute);
            }
            firstEnter.value = false;
        }
    );
};

export const usePageLeave = (cb: PageStateCallback = () => {}) => {
    watch(
        () => useAppStore().pageState,
        (val) => val === PageState.LeaveStart && cb()
    );
};
