<script setup>
    import { onMounted, onUnmounted } from 'vue';

    import { LoaderEvent } from '@resn/gozer-loading';
    import {
        GLTFLoader,
        ImageBlobLoader,
        ImageLoader,
        JSONLoader,
        KTXLoader,
        TextureLoader,
    } from '@resn/gozer-loading/loaders';
    import { useLoaderContext } from '@resn/gozer-vue/loading';

    import { useAppStore } from '~/stores/app';

    const app = useAppStore();

    const context = useLoaderContext({
        concurrency: 10,
        loaders: [JSONLoader, ImageLoader, ImageBlobLoader, TextureLoader, GLTFLoader, KTXLoader],
    });

    context.on(LoaderEvent.LOAD_PROGRESS, (loader) => {
        const { percentage } = loader.progress;
        app.setLoadProgress(percentage);
    });

    onMounted(() => {
        // console.log('[LoaderProvider] mounted', context);
        // context.start();
    });

    onUnmounted(() => {
        // AND SOMEWAY TO CLEAN CHAIN..
        // console.log('[LoaderProvider] unmounted', context);
        context.dispose();
    });

    defineExpose({ context });
</script>

<template>
    <slot />
</template>
